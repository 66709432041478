@import 'theme';

.App {
  text-align: center;
}

.app-container {
  margin: 20px auto 0 auto;
  /*// top and bottom to 0, left and right to auto (to center)*/
  max-width: 50rem;
  /*// 500px */
  padding: 1.3rem;
  background: white;
  text-align: center;
  /* border: 1px solid #e8e8e8; */
  margin-bottom: 1.3rem;
  padding: 1.3rem;
}

.intro-container {
  margin: 0 auto 0 auto;
  /*// top and bottom to 0, left and right to auto (to center)*/
  max-width: 40rem;
  /*// 500px */
  padding: 10px 4.3rem 0;
  background: white;
  text-align: center;
  /* border: 1px solid #e8e8e8; */
  /* margin-bottom: 1.3rem; */
}

.rightAnchor {
  text-decoration: none !important;
  /* float: right; */
  text-align: right;
}

.normal {
  /* display: block; */
  /* background: #ffffff; */
  border: 2px solid $primary-button;
  background-color: $primary-button;
  width: 86%;
  line-height: 2;

  color: white;
  margin-top: 15px;
  /* cursor: pointer;
    font-weight: 600;
    margin: 0.5rem auto;
    align-content: center;
    line-height: 1;
    outline: none;
    border-radius: 3px;
    transition: background 0.2s ease, transform 0.2s ease; */
}

.secondary {
  border: 2px solid $secondary-button;
  background-color: $secondary-button;
}

.tiny {
  position: relative;
  top: 20px;
  border: 2px solid #6c757d;
  background-color: #6c757d;
  // width: 43%;
  margin: 0 60px;
  font-size: 12px;
  line-height: 1.5;
  padding: 5px 10px;

  color: white;
  margin-top: 15px;
}

button:disabled {
  background: #e8e8e8;
  color: grey;
  border-color: $disabled-button-outline;
}

.centeredLogo {
  display: block;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background-color: $logo-bg;
}

.iconImg {
  margin: 0 auto;
  background-color: $logo-bg;
}

.btnDiv {
  display: flex;
  justify-content: center;
}

/* // BEM - Block, Element, Modifier */
