@import "theme";

* {
  margin: 0;
  padding: 0;
}

@import url($primary-font-link);

/* // this sets our 'rem' units */
html {
  font-size: 62.5%;
  /* // 10px as 100% = 16px */
}

body {
  background: #f2f2f2;
  color: #555555;
  font-family: $primary-font-name, "Helvetica Neue", "Helvetica", Helvetica,
    Arial, sans-serif;
  font-size: 1.6rem;

  iframe#webpack-dev-server-client-overlay {
    display: none;
  }
}

input,
button,
select {
  font-size: 1.3rem;
}

header {
  text-align: center;
}

h1 {
  font-weight: 300;
  font-size: 2.4rem;
}

form {
  /* display: flex; */
  background: white;
  /* border: 1px solid #e8e8e8; */
  margin-bottom: 1.3rem;
  padding: 1.3rem;
}

form input,
form select {
  border: 1px solid #e8e8e8;
  width: 80%;
  display: block;
  align-content: center;
  /* flex-grow: 1; */
  padding: 1.3rem;
  margin: 2rem auto;
}

.thin {
  max-width: 60%;
}

button:active {
  background: #e8e8e8;
  color: purple;
  border-color: purple;
}

button::-moz-focus-inner {
  border: 0;
}

.maintenance {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

  background-color: #FBB040;
  height: 100vh;
  text-align: center;
  margin: auto 0;

  div {
    padding: 20px;
    background-color: #707070;
    ;
  }

  h2 {
    padding: 20px;
  }
}

:root {
  --swiper-navigation-color: #85be57 !important;
  --swiper-theme-color: #85be57 !important;
  --swiper-pagination-color: #85be57;
  --swiper-preloader-color: #85be57;
}

